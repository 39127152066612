import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Menu, X, Search, Pin } from 'lucide-react';
import Home from './pages/Home';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import utilities from './config/utilities';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pinnedTools, setPinnedTools] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const savedPinnedTools = localStorage.getItem('pinnedTools');
    if (savedPinnedTools) {
      setPinnedTools(JSON.parse(savedPinnedTools));
    }
  }, []);

  const handleSearch = e => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const togglePinTool = toolName => {
    const updatedPinnedTools = pinnedTools.includes(toolName)
      ? pinnedTools.filter(name => name !== toolName)
      : [...pinnedTools, toolName];

    setPinnedTools(updatedPinnedTools);
    localStorage.setItem('pinnedTools', JSON.stringify(updatedPinnedTools));
  };

  const categorizedUtilities = {};
  utilities.forEach(utility => {
    if (!categorizedUtilities[utility.category]) {
      categorizedUtilities[utility.category] = [];
    }
    categorizedUtilities[utility.category].push(utility);
  });

  const filteredCategories = {};
  for (const [category, utils] of Object.entries(categorizedUtilities)) {
    const filteredUtils = utils.filter(
      utility =>
        utility.name.toLowerCase().includes(searchTerm) ||
        category.toLowerCase().includes(searchTerm)
    );
    if (filteredUtils.length > 0) {
      filteredCategories[category] = filteredUtils;
    }
  }

  const pinnedUtilities = utilities.filter(utility =>
    pinnedTools.includes(utility.name)
  );

  const renderUtilityButton = utility => (
    <button
      key={utility.name}
      className={`w-full flex items-center px-4 py-2 text-sm hover:bg-gray-700 relative group ${
        location.pathname === utility.path ? 'bg-gray-700' : ''
      }`}
      onClick={() => navigate(utility.path)}
    >
      <utility.icon size={20} className="mr-2" />
      <span className={isOpen ? 'block' : 'hidden'}>{utility.name}</span>
      {isOpen && (
        <Pin
          size={16}
          className={`absolute right-2 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer ${
            pinnedTools.includes(utility.name)
              ? 'text-blue-400'
              : 'text-gray-400'
          }`}
          onClick={e => {
            e.stopPropagation();
            togglePinTool(utility.name);
          }}
        />
      )}
    </button>
  );

  return (
    <div
      className={`bg-gray-800 text-white ${
        isOpen ? 'w-64' : 'w-16'
      } transition-all duration-300 ease-in-out overflow-y-auto`}
    >
      <div className="p-4 flex justify-between items-center">
        <h1 className={`font-bold ${isOpen ? 'block' : 'hidden'}`}>
          Developer Utilities Online
        </h1>
        <button
          onClick={toggleSidebar}
          className="p-2 rounded hover:bg-gray-700"
          aria-label={isOpen ? 'Close sidebar' : 'Open sidebar'}
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>
      {isOpen && (
        <div className="px-4 mb-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search tools..."
              className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={handleSearch}
            />
            <Search
              size={18}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            />
          </div>
        </div>
      )}
      <nav>
        {isOpen && pinnedUtilities.length > 0 && (
          <div className="mb-4">
            <h2 className="px-4 py-2 text-sm font-semibold text-gray-400">
              Pinned Tools
            </h2>
            {pinnedUtilities.map(utility => renderUtilityButton(utility))}
          </div>
        )}
        {Object.entries(filteredCategories).map(([category, utils]) => (
          <div key={category} className="mb-4">
            {isOpen && (
              <h2 className="px-4 py-2 text-sm font-semibold text-gray-400">
                {category}
              </h2>
            )}
            {utils.map(utility => renderUtilityButton(utility))}
          </div>
        ))}
      </nav>
    </div>
  );
};

function App() {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <Router>
      <div className="flex h-screen bg-gray-100">
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        <div className="flex-1 flex flex-col overflow-hidden">
          <Header />
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
            <Routes>
              <Route path="/" element={<Home />} />
              {utilities.map(utility => (
                <Route
                  key={utility.path}
                  path={utility.path}
                  element={<utility.component />}
                />
              ))}
            </Routes>
          </main>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
