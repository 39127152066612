import React, { useState } from 'react';
import { Search } from 'lucide-react';
import UtilityCard from '../components/utilities/UtilityCard';
import utilities from '../config/utilities'; // Import the utilities configuration

const Home = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');

  const filteredUtilities = utilities.filter(
    utility =>
      utility.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory === 'All' || utility.category === selectedCategory)
  );

  const categories = ['All', ...new Set(utilities.map(u => u.category))];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 text-center">
        Developer Utilities Hub
      </h1>

      <div className="mb-6 flex flex-col sm:flex-row items-center justify-between">
        <div className="relative w-full sm:w-64 mb-4 sm:mb-0">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search utilities..."
            className="w-full pl-10 pr-4 py-2 border rounded-md"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <select
          className="w-full sm:w-auto px-4 py-2 border rounded-md"
          value={selectedCategory}
          onChange={e => setSelectedCategory(e.target.value)}
        >
          {categories.map(category => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {filteredUtilities.map(utility => (
          <UtilityCard key={utility.id} utility={utility} />
        ))}
      </div>
    </div>
  );
};

export default Home;
