import React from 'react';

const StringCounter = () => {
  return (
    <div>
      <h1>String / Character Counter</h1>
      <p>Count characters, words, and lines in a given text.</p>
      {/* Add your component's code here */}
    </div>
  );
};

export default StringCounter;
