import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';

function Header() {
  return (
    <header className="bg-gray-800 p-4 text-white">
      <div className="flex justify-center items-center">
        <img src={logo} alt="Logo" className="h-10" />
      </div>
      <nav className="mt-4">
        <ul className="flex justify-center space-x-4">
          <li>
            <Link to="/" className="hover:text-gray-300">
              Home
            </Link>
          </li>
          <li>
            <Link to="/rgb-to-hex" className="hover:text-gray-300">
              RGB to HEX Converter
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
