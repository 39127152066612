import {
  Layout,
  Code,
  Terminal,
  Palette,
  Clock,
  Shield,
  Type,
  FileText,
} from 'lucide-react';
import JsonFormatter from '../pages/JsonFormatter';
import JsonPathExplorer from '../pages/JsonPathExplorer';
import JsonSchemaValidator from '../pages/JsonSchemaValidator';
import StringCounter from '../pages/StringCounter';
import StringCaseConverter from '../pages/StringCaseConverter';
import RegexTester from '../pages/RegexTester';
import ColorPicker from '../pages/ColorPicker';
import ColorPaletteGenerator from '../pages/ColorPaletteGenerator';
import TypeScaleGenerator from '../pages/TypeScaleGenerator';
import TimestampConverter from '../pages/TimestampConverter';

const utilities = [
  {
    name: 'JSON Formatter and Validator',
    icon: Layout,
    component: JsonFormatter,
    path: '/json-formatter',
    category: 'Data Formatting',
  },
  {
    name: 'JSON Path Explorer',
    icon: Code,
    component: JsonPathExplorer,
    path: '/json-path-explorer',
    category: 'Data Formatting',
  },
  {
    name: 'JSON Schema Validator',
    icon: Shield,
    component: JsonSchemaValidator,
    path: '/json-schema-validator',
    category: 'Data Formatting',
  },
  {
    name: 'String/Character Counter',
    icon: FileText,
    component: StringCounter,
    path: '/string-counter',
    category: 'Text Processing',
  },
  {
    name: 'String Case Converter',
    icon: Type,
    component: StringCaseConverter,
    path: '/string-case-converter',
    category: 'Text Processing',
  },
  {
    name: 'Regex Tester',
    icon: Terminal,
    component: RegexTester,
    path: '/regex-tester',
    category: 'Coding Utilities',
  },
  {
    name: 'Color Picker and Converter',
    icon: Palette,
    component: ColorPicker,
    path: '/color-picker',
    category: 'Color and Design',
  },
  {
    name: 'Color Palette Generator',
    icon: Palette,
    component: ColorPaletteGenerator,
    path: '/color-palette-generator',
    category: 'Color and Design',
  },
  {
    name: 'Type Scale Generator',
    icon: Type,
    component: TypeScaleGenerator,
    path: '/type-scale-generator',
    category: 'Color and Design',
  },
  {
    name: 'Timestamp Converter',
    icon: Clock,
    component: TimestampConverter,
    path: '/timestamp-converter',
    category: 'Time and Date',
  },
  // URL Encoder/Decoder
  // Base64 Encoder/Decoder
  // JWT Decoder
  // Type combination tester
  // Hash Generator (MD5, SHA-256)
];

export default utilities;
