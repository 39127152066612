import React from 'react';

const RegexTester = () => {
  return (
    <div>
      <h1>Regex Tester</h1>
      <p>Test and debug regular expressions with real-time matching and explanation.</p>
      {/* Add your component's code here */}
    </div>
  );
};

export default RegexTester;
